import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import InitialPage from './InitialPage';
import QuestionsContainer from './QuestionsContainer';

function Main() {
  return (
    <Router>
      <Switch>
        <Route path="/questions">
          <QuestionsContainer />
        </Route>
        <Route path="/">
          <InitialPage />
        </Route>
      </Switch>
    </Router>
  );
}

export default Main;
