import { useEffect, useReducer, useState } from 'react';
import QuestionPanel from 'components/QuestionPanel';
import * as actions from 'services/actions';
import validate from 'services/validator';
import { calculateResults, dataToChartData } from 'services/reportValuesCalculator';
import ReactDOMServer from 'react-dom/server';
import RiskMapChart from 'components/RiskMapChart';
import colorsUtils from 'utils/colors'

function reducer(state, action) {
  switch (action.type) {
    case actions.SET_QUESTIONS:
      const questions = action.payload;
      return { ...state, questions, id: 1, question: questions[1] };

    case actions.SET_VARIABLES:
      const variables = action.payload;
      return { ...state, variables };

    case actions.SET_NEXT_ID:
      const nextId = action.payload;
      const prevId = state.id;
      const question = state.questions[nextId];
      return {
        ...state,
        errorMessage: '',
        previousIds: [...state.previousIds, prevId],
        id: nextId,
        question,
      };

    case actions.SET_PREV_ID:
      const lastId = state.previousIds.slice(-1).pop();
      const newPrevId = state.previousIds.slice(0, -1);
      const lastQuestion = state.questions[lastId];
      return {
        ...state,
        errorMessage: '',
        previousIds: newPrevId,
        id: lastId,
        question: lastQuestion,
      };

    case actions.CHANGE_INPUT_VALUE:
      const { inputName, inputValue } = action.payload;
      return { ...state, errorMessage: '', input: { ...state.input, [inputName]: inputValue } };

    case actions.SELECT:
      const { id, selectedOption, redirectId } = action.payload;
      return {
        ...state,
        errorMessage: '',
        selectedOptions: { ...state.selectedOptions, [id]: { selectedOption, redirectId } },
      };

    case actions.SET_ERROR:
      const errorMessage = action.payload;
      return { ...state, errorMessage };

    default:
      throw new Error('Action not defined');
  }
}

const initialState = {
  id: 1,
  variables: [],
  previousIds: [],
  selectedOptions: {},
  input: {},
};

function QuestionsContainer() {
  window.parent.postMessage(['setStatus', 'questionnaire_started'], '*');

  const [state, dispatch] = useReducer(reducer, initialState);
  const [isGenerating, setIsGenerating] = useState(false);

  const { id, question, variables, selectedOptions, input, errorMessage } = state;

  useEffect(() => {
    async function fetchQuestions() {
      const resp = await fetch(`/api/questions`);
      const json = await resp.json();
      dispatch(actions.setQuestions(json));
    }
    fetchQuestions();
  }, []);

  useEffect(() => {
    async function fetchVariables() {
      const resp = await fetch(`/api/variables`);
      const json = await resp.json();
      dispatch(actions.setVariables(json));
    }
    fetchVariables();
  }, []);

  function handlePrevId(e) {
    e.preventDefault();
    dispatch(actions.setPrevId());
  }
  function handleNextId(e) {
    e.preventDefault();
    const error = validate(state);
    if (!error) {
      const nextId = question.redirectId || selectedOptions[id]?.redirectId;
      dispatch(actions.setNextId(nextId));
    } else {
      dispatch(actions.setError(error));
    }
  }

  async function handleSeeReport(e) {
    e.preventDefault();

    setIsGenerating(true);

    const results = calculateResults(state, variables);
    const chartData = dataToChartData(results);
    const chartHtml = ReactDOMServer.renderToStaticMarkup(
      <RiskMapChart data={chartData} variables={variables} />
    );
    
    const data = { results, model: state, chartHtml, colors:colorsUtils.getColors() , search: window.location.search};
    const response = await fetch('/api/riskmap/html', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    setIsGenerating(false);

    const blob = await response.blob();
    const html = await blob.text();
    document.open();
    document.write(html);
    document.close();

    const height = document.getElementsByTagName('body')[0].offsetHeight;
    window.parent.postMessage(['setHeight', height], '*');
    window.parent.postMessage(['setStatus', 'showing_result'], '*');

    await fetch('/api/riskmap', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  }

  const selectedOption = selectedOptions[id]?.selectedOption;

  if (!question) return null;

  return (
    <QuestionPanel
      question={question}
      selectedOption={selectedOption}
      dispatch={dispatch}
      input={input}
      errorMessage={errorMessage}
      onPrevId={handlePrevId}
      onNextId={handleNextId}
      onSeeReport={handleSeeReport}
      isGenerating={isGenerating}
    />
  );
}

export default QuestionsContainer;
