function calculateResults(model, variables) {
  let data = {};
  for (let v of variables) {
    data[v.name] = 0;
  }
  const { questions, selectedOptions } = model;
  const optionsEntries = Object.entries(selectedOptions);
  for (let [questionId, option] of optionsEntries) {
    const question = questions[questionId];
    const selectedOption = question.options[option.selectedOption];
    const { categories } = selectedOption;
    for (let category of categories) {
      let prevValue = data[category.name] || 0;
      data[category.name] = prevValue > category.value ? prevValue : category.value;
    }
  }
  return data;
}
function calculateTextByResult(texts, results) {
  return texts.filter((text) => {
    let keysEntries = Object.entries(text.key);
    for (let [key, range] of keysEntries) {
      if (!isInRange(range, results[key])) {
        // One key is not in range, filter out
        return false;
      }
    }
    // All Keys exist and are in Range
    return true;
  });

  function isInRange(range, value) {
    return range[0] <= value && value <= range[1];
  }
}

function dataToChartData(data) {
  return Object.entries(data)
    .filter(([k, v]) => !k.startsWith('_')) // exclude private variables
    .map(([k, v], idx) => {
      return { id: idx, label: k, value: v };
    });
}

export { calculateResults, calculateTextByResult, dataToChartData };
