export const SET_QUESTIONS = 'setQuestions';
export const SET_VARIABLES = 'setVariables';
export const SELECT = 'select';
export const CHANGE_INPUT_VALUE = 'changeInputValue';
export const SET_NEXT_ID = 'setNextId';
export const SET_PREV_ID = 'setPrevId';
export const SET_ERROR = 'setError';

export function setQuestions(questions) {
  return {
    type: SET_QUESTIONS,
    payload: questions,
  };
}
export function setVariables(variables) {
  return {
    type: SET_VARIABLES,
    payload: variables
  };
}
export function selectOption(questionId, optionIndex, redirectId) {
  return {
    type: SELECT,
    payload: { id: questionId, selectedOption: optionIndex, redirectId },
  };
}

export function setNextId(id) {
  return {
    type: SET_NEXT_ID,
    payload: id,
  };
}
export function setPrevId() {
  return {
    type: SET_PREV_ID,
  };
}

export function changeInput(inputName, inputValue) {
  return {
    type: CHANGE_INPUT_VALUE,
    payload: { inputName, inputValue },
  };
}

export function setError(errorMessage) {
  return {
    type: SET_ERROR,
    payload: errorMessage,
  };
}
