import { selectOption } from 'services/actions';

function OptionsContent({ id, options, name, dispatch, selectedOption = null }) {
  function handleSelected(e) {
    let selectedValue = parseInt(e.target.value, 10);
    const redirectId = options[selectedValue].redirectId;
    dispatch(selectOption(id, selectedValue, redirectId));
  }
  return (
    <div
      role="group"
      className="formulate-input-element formulate-input-element--group formulate-input-group md:inline-flex md:items-center md:space-x-2 flex space-x-2 pt-3"
    >
      <div
        data-classification="box"
        data-type="radio"
        className="formulate-input-group-item mb-0 quotation-radio-labels flex flex-col"
        data-has-value="true"
      >
        {options.map((o, idx) => {
          const optId = `opt-${name}-${idx}`;
          return (
            <div key={optId} className="inline-flex items-center pt-2">
              <div
                data-type="radio"
                className="formulate-input-element formulate-input-element--radio md:inline-flex md:items-center md:space-x-2"
              >
                <input
                  id={optId}
                  name={name}
                  value={idx}
                  checked={idx === selectedOption}
                  onChange={handleSelected}
                  type="radio"
                  hasgivenname="true"
                  aria-describedby="formulate--quotation-leave-of-absence-daily-compensation-1-help"
                  className="form-radio text-primary  cursor-pointer "
                  formshouldshowerrors="true"
                  hasvalue="true"
                />
                <label htmlFor={optId} className="text-gray-700 cursor-pointer ml-2"></label>
              </div>
              <label id={optId} htmlFor={optId} className="font-body font-light text-nowo-gray-600 text-sm">
                {o.title}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default OptionsContent;
