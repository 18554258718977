import { isOptionType } from 'components/ContentFactory';

function validate(model) {
  const { id, selectedOptions, question, input } = model;

  if (isOptionType(question.type)) {
    if (!selectedOptions[id]) {
      return 'Por favor, seleccione una opción';
    }
    // Valid
    return null;
  }

  if (!input[question.name] && isRequiredInput(id)) {
    return `Por favor, rellene el campo`;
  }

  // Valid
  return null;
}

// ATTENTION: This logic should be provided by the server via the excel input
// Hardcoding the values meanwhile
const listValidIds = [102, 103];
function isRequiredInput(id) {
  return !listValidIds.includes(id);
}

export default validate;
