import { Link } from 'react-router-dom';
import { useHeightNotifier } from 'services/customHooks';
import colorsUtils from 'utils/colors'

function InitialPage() {
  window.parent.postMessage(['setStatus', 'initial_page'], '*');

  const queryParams = window.location.search;

  // Set cookie with initialization params in query string, useful to send requests
  // to Thenowo API later using these params.
  document.cookie = `search=${queryParams}`;
  const params = new URL(window.location.href).searchParams;
  colorsUtils.setColors(
    {
      primary: params.get('primary'),
      primaryHover: params.get('primaryHover'),
      secondary: params.get('secondary'),
      secondaryHover: params.get('secondaryHover'),
      tertiary: params.get('tertiary'),
      tertiaryHover: params.get('tertiaryHover')
    }
  )

  let divElement = useHeightNotifier();

  if (!queryParams) return <pre className="p-4">Error: no se recibieron los datos de usuario</pre>;

  let cardHtml = (
    <div ref={divElement} id="card" className="w-full flex justify-center text-primary">
      <div className="flex flex-col w-full md:w-5/9 items-center max-w-screen-sm mt-2 bg-nowo-landing-quotation shadow-nowo-card rounded-lg px-4 py-2">
        <h1 className="font-heading font-light  text-nowo-gray-600 pt-2 ">Mapa de Riesgos</h1>
        <p className="font-body text-gray-600 text-xs m-4 pb-2">
          Calcularemos tu mapa de riesgos a partir de una serie de preguntas.
        </p>
        <div className="pb-2">
          <Link to={`/questions${queryParams}`}>
            <button
              data-purpose="leave-of-absence-daily-compensation"
              type="submit"
              className="flex items-center btn btn-primary capitalize"
            >
              Comenzar
            </button>
          </Link>
        </div>
      </div>
    </div>
  );

  let contentHtml = (
    <div className="flex flex-col items-center justify-center min-h-screen bg-nowo-gray-75">
      <div className="w-full">
        <div className="md:px-16">{cardHtml}</div>
      </div>
    </div>
  );

  return window.location !== window.parent.location ? cardHtml : contentHtml;
}

export default InitialPage;
