
var colorsConfig = {}

function setColors(colors) {
    colorsConfig = {
        '--primary': '#' + colors.primary,
        '--primary-hover': '#' + colors.primaryHover,
        '--secondary': '#' + colors.secondary,
        '--secondary-hover': '#' + colors.secondaryHover,
        '--tertiary': '#' + colors.tertiary,
        '--tertiary-hover': '#' + colors.tertiaryHover
    }
    document.documentElement.style.setProperty('--primary', '#' + colors.primary)
    document.documentElement.style.setProperty('--primary-hover', '#' + colors.primaryHover)
    document.documentElement.style.setProperty('--secondary', '#' + colors.secondary)
    document.documentElement.style.setProperty('--secondary-hover', '#' + colors.secondaryHover)
    document.documentElement.style.setProperty('--tertiary', '#' + colors.tertiary)
    document.documentElement.style.setProperty('--tertiary-hover', '#' + colors.tertiaryHover)
}

function getColors(){
    return colorsConfig
}
export default {
    setColors,
    getColors
}