import {
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryTheme,
  VictoryAxis,
  VictoryContainer,
} from 'victory';

function getTestData() {
  return excelData.split('\n').map((r, idx) => {
    const [label, value] = r.split('\t');

    return { id: idx + 1, label, value: parseInt(value, 10) };
  });
}

const excelData = `Tú mismo	9
  Integridad de las personas	7
  Instalaciones y maquinaria	3
  Fabricación y montaje	5
  Directivos y altos cargos	0
  Riesgos profesionales	10
  Impagos y crédito comercial	3
  Daños a a las cosas.	3
  Ciberriesgo	8`;

function getColorByValue({ datum }) {
  const opacity = 0.40;

  if (datum.value < 4) return `rgba(0, 107, 18, ${opacity})`;

  if (datum.value < 8) return `rgba(224, 127, 0, ${opacity})`;

  return `rgba(255, 0, 0, ${opacity})`;
}

const RiskMapChart = ({ data = getTestData(), variables }) => {
  function getVariableDescriptionBy(name) {
    return variables?.find((i) => i.name === name)?.description ?? name;
  }

  return (
    <div id="riskMapChart">
      <VictoryChart
        containerComponent={<VictoryContainer responsive={true} />}
        theme={VictoryTheme.material}
        domainPadding={{ x: [10, 0], y: [0, data.length] }}
      >
        <VictoryAxis tickFormat={(_) => ''} style={{ ticks: {stroke: 'transparent' }, axis: { stroke: 'none' } }} />
        <VictoryAxis dependentAxis tickCount={10} style={{ tickLabels: { fill: 'transparent' }, axis: { stroke: 'none' }, ticks: { stroke: 'none' }, grid: { stroke: 'none' } }} />

        <VictoryBar
          alignment="middle"
          barWidth={22}
          style={{
            data: {
              fill: getColorByValue,
            },
          }}
          labelComponent={
            <VictoryLabel
              textAnchor="start"
              x={45}
              //dy={-7}
              style={{ fontFamily: 'Open Sans', fontSize: 12, fill: '#040404' }}
              text={({ datum }) => `${getVariableDescriptionBy(datum.label)}: ${datum.value}`}
            />
          }
          horizontal
          data={data}
          x="id"
          y="value"
        />
      </VictoryChart>
    </div>
  );
};

export default RiskMapChart;
