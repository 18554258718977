import { useEffect } from 'react';
import { changeInput } from 'services/actions';
import { useLocation } from 'react-router-dom';
import InputMask from 'react-input-mask';

function InputContent({ type = 'text', mask, defaultValue, input = {}, name, dispatch }) {
  const { search } = useLocation();
  const value = input[name] ?? null;
  function handleChange(e) {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    dispatch(changeInput(inputName, inputValue));
  }

  useEffect(() => {
    var searchParams = new URLSearchParams(search);
    if (value === null && searchParams.has(name)) {
      const inputValue = searchParams.get(name);
      dispatch(changeInput(name, inputValue));
    }
  }, [value, name, dispatch, search]);

  if (!mask) {
    return (
      <input
        className="input"
        name={name}
        type={type}
        value={value ?? ''}
        onChange={handleChange}
        style={{"height": "1.9rem","fontSize": "0.75rem"}}
      />
    );
  }

  return (
    <InputMask
      className="input"
      mask={mask}
      alwaysShowMask
      value={value ?? ''}
      name={name}
      onChange={handleChange}
    />
  );
}

export default InputContent;
