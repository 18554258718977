import InputContent from './InputContent';
import OptionsContent from './OptionsContent';

const TYPE_TEXT = 'texto';
const TYPE_OPTIONS = 'opciones';
const TYPE_PHONE = 'teléfono';
const TYPE_PHONE2 = 'telefono';
const TYPE_DATE = 'fecha';

// This could be extracted to a service if it grows
export function isOptionType(type) {
  let lowerType = type?.toLowerCase();
  return TYPE_OPTIONS === lowerType;
}

function ContentFactory({ type = '', ...rest }) {
  let lowerType = type.toLowerCase();
  switch (lowerType) {
    case TYPE_TEXT:
      return <InputContent {...rest} />;
    case TYPE_OPTIONS:
      return <OptionsContent {...rest} />;
    case TYPE_PHONE:
    case TYPE_PHONE2:
      return <InputContent mask="999 999 999" {...rest} />;
    case TYPE_DATE:
      return <InputContent mask="99/99/9999" {...rest} />;
    default:
      return <div>No type</div>;
  }
}

export default ContentFactory;
