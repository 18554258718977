import ContentFactory from './ContentFactory';
import { useHeightNotifier } from 'services/customHooks';

function replaceTokens(text, input) {
  if (!text) return text;

  return text.replace('{nombre}', input['nombre']);
}

export default function QuestionPanel({
  question,
  errorMessage,
  onPrevId,
  onNextId,
  onSeeReport,
  isGenerating,
  ...rest
}) {
  const divElement = useHeightNotifier();

  const { isLast } = question;

  if (isLast)
    window.parent.postMessage(['setStatus', 'completed_questionnaire'], '*');

  const updatedExplanation = replaceTokens(question.explanation, rest.input);

  let cardHtml = (
    <div ref={divElement} className="w-full flex justify-center text-primary">
      <div className="flex flex-col w-full md:w-5/9 items-center max-w-screen-sm mt-2 bg-nowo-landing-quotation shadow-nowo-card rounded-lg px-5 py-2">
        <form
          error-behavior="live"
          name="quotation"
          className="flex flex-col w-full mt-3 formulate-form formulate-form--quotation"
        >
          <h1 className="font-heading font-light text-nowo-gray-600 ">{question.title}</h1>
          <p className="font-body pt-1 text-gray-600 text-xs">{updatedExplanation}</p>
          <div className="pt-2 pb-2">
            <ContentFactory type={question.type} {...question} {...rest} onNextId={onNextId} />
          </div>
          <p className="font-body pb-6 text-red-500 text-xs">{errorMessage}</p>
          <div className="flex justify-between w-full mt-2 mb-4 space-x-4">
            <button
              className="flex items-center btn btn-tertiary capitalize"
              style={{ opacity: question.id === 1 ? 0 : 1 }}
              onClick={onPrevId}
            >
              <svg fill="currentColor" viewBox="0 0 20 20" className="h-6">
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              Anterior
            </button>
            {isLast && (
              <button
                disabled={isGenerating}
                className="flex items-center btn btn-primary"
                onClick={onSeeReport}
              >
                Ver Mapa de Riesgos
              </button>
            )}
            {!isLast && (
              <button
                data-purpose="leave-of-absence-daily-compensation"
                className="flex items-center btn btn-primary capitalize"
                onClick={onNextId}
              >
                {' siguiente '}
                <svg fill="currentColor" viewBox="0 0 20 20" className="h-6">
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );

  let contentHtml = (
    <div className="flex flex-col items-center justify-center min-h-screen bg-nowo-gray-75">
      <div className="w-full">
        <div className="md:px-16">{cardHtml}</div>
      </div>
    </div>
  );

  return window.location !== window.parent.location ? cardHtml : contentHtml;
}
