import { useRef, useEffect } from 'react';

export function useHeightNotifier() {
  let divElement = useRef();
  useEffect(() => {
    const resizeListener = () => {
      const currentDiv = divElement.current;
      if (currentDiv) {
        const height = currentDiv.clientHeight;
        // console.log('The height of the card is', height);
        window.parent.postMessage(['setHeight', height], '*');
      }
    };
    resizeListener();
    window.addEventListener('resize', resizeListener);
    return () => window.removeEventListener('resize', resizeListener);
  });
  return divElement;
}
